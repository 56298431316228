import React, { Component } from "react";
import PropTypes from "prop-types";
import footerStrings from "../../source/footer.json";
import imagePaths from "../../source/images.json";

class FooterPanel extends Component {
  render() {
    const {
      facebookLink,
      youtubeLink,
      instagramLink,
      locationLink,
      webLink,
      graceAnointingTitle,
      graceAnointingDescription,
      aboutUsTitle,
      aboutUsLinks,
      contactInfoTitle,
      contactInfo,
    } = footerStrings;

    const { graceAnointingImage } = imagePaths;

    return (
      <div className="container-fluid footer py-3">
        <div className="container">
          <div className="row g-3">
            {/* Grace Anointing Section */}
            <div className="col-md-4">
              <div className="footer-item d-flex flex-column align-items-start">
                <img
                  src={graceAnointingImage}
                  alt="Grace Anointing"
                  style={{
                    width: "80px",
                    height: "30px",
                    objectFit: "cover",
                    marginBottom: "0.5rem",
                  }}
                />
                <h3 className="text-white strong mb-2">
                  {graceAnointingTitle}
                </h3>
                <p className="text-white mb-0">{graceAnointingDescription}</p>
              </div>
            </div>

            {/* About Us Section */}
            <div className="col-md-4">
              <div className="footer-item d-flex flex-column align-items-start">
                <h4 className="text-white strong mb-2">{aboutUsTitle}</h4>
                {aboutUsLinks.map((info, index) => (
                  <a
                    key={index}
                    href={info.href || "#"}
                    target ={info.target || "_self"}
                    className="text-white d-block mb-1"
                  >
                    {info.text}
                  </a>
                ))}
              </div>
            </div>

            {/* Contact Info Section */}
            <div className="col-md-4">
              <div className="footer-item d-flex flex-column align-items-start">
                <h4 className="text-white strong mb-2">{contactInfoTitle}</h4>
                {contactInfo.map((info, index) => (
                  <a
                    key={index}
                    href={info.href || "#"}
                    className="text-white d-block mb-1"
                  >
                    <i className={`${info.icon} me-1`} /> {info.text}
                  </a>
                ))}

                {/* Social Media Links  */}
                <div>
                  <br />
                </div>
                <div className="col-12 d-flex justify-content-start">
                  <a
                    className="btn btn-md-square rounded-circle me-2 social-icon facebook"
                    href={facebookLink}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Facebook"
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a
                    className="btn btn-md-square rounded-circle me-2 social-icon youtube"
                    href={youtubeLink}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="YouTube"
                  >
                    <i className="fab fa-youtube" />
                  </a>
                  <a
                    className="btn btn-md-square rounded-circle me-2 social-icon instagram"
                    href={instagramLink}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Instagram"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                  <a
                    className="btn btn-md-square rounded-circle me-2 social-icon web"
                    href={webLink}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Website"
                  >
                    <i className="fas fa-globe" />
                  </a>

                  <a
                    className="btn btn-md-square rounded-circle social-icon location"
                    href={locationLink}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Location"
                  >
                    <i className="fas fa-map-marker-alt" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Copyright and Powered By Section */}
          <div className="row mt-4">
            <div className="col-12 text-center">
              <p className="text-white mb-1">
                &copy; {new Date().getFullYear()} Grace Anointing Ministries -
                MRC. All rights reserved.
              </p>
              <p className="text-white mb-0">Powered by Holy Spirit</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FooterPanel.propTypes = {
  footerData: PropTypes.shape({
    facebookLink: PropTypes.string,
    youtubeLink: PropTypes.string,
    instagramLink: PropTypes.string,
    locationLink: PropTypes.string,
    graceAnointingTitle: PropTypes.string,
    graceAnointingDescription: PropTypes.string,
    aboutUsTitle: PropTypes.string,
    aboutUsLinks: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string,
        text: PropTypes.string,
      })
    ),
    contactInfoTitle: PropTypes.string,
    contactInfo: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string,
        icon: PropTypes.string,
        text: PropTypes.string,
      })
    ),
  }),
};

FooterPanel.defaultProps = {
  footerData: {
    facebookLink: "#",
    youtubeLink: "#",
    instagramLink: "#",
    locationLink: "#",
    webLink: "#",
    graceAnointingTitle: "Grace Anointing",
    graceAnointingDescription: "Grace Anointing ",
    aboutUsTitle: "About Us",
    aboutUsLinks: [],
    contactInfoTitle: "Contact Info",
    contactInfo: [],
  },
};

export default FooterPanel;
