import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/AuthContext"; // Assuming you have an AuthContext
import { jwtDecode } from "jwt-decode"; // Correct named import

const AutoLogout = ({ timeout = 3600000, children }) => {
  // Default to 1 hour timeout
  const [lastActivity, setLastActivity] = useState(Date.now());
  const navigate = useNavigate();
  const { logout } = useAuth(); // Assuming logout method from AuthContext

  // Function to check if the token is expired
  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const { exp } = jwtDecode(token);
      return Date.now() >= exp * 1000; // Convert exp to milliseconds
    } catch (e) {
      console.error("Failed to decode token", e);
      return true; // Return true if decoding fails
    }
  };

  useEffect(() => {
    const resetActivityTimer = () => {
      setLastActivity(Date.now());
    };

    // Listen for any user interaction to reset the inactivity timer
    window.addEventListener("mousemove", resetActivityTimer);
    window.addEventListener("keydown", resetActivityTimer);
    window.addEventListener("click", resetActivityTimer);

    // Check token expiration
    const token = localStorage.getItem("token");
    if (token && isTokenExpired(token)) {
      console.log("Token expired. LOGING OUT...");
      logout(); // Log out the user from the session
      navigate("/login"); // Redirect to the login page
      return; // Exit early if token is expired
    }

    // Inactivity timeout check
    const checkInactivity = setInterval(() => {
      if (Date.now() - lastActivity > timeout) {
        console.log("User inactive. LOGING OUT...");
        logout(); // Log out the user from the session
        navigate("/login"); // Redirect to the login page
      }
    }, 1000); // Check every second

    // Cleanup event listeners and interval on component unmount
    return () => {
      window.removeEventListener("mousemove", resetActivityTimer);
      window.removeEventListener("keydown", resetActivityTimer);
      window.removeEventListener("click", resetActivityTimer);
      clearInterval(checkInactivity);
    };
  }, [lastActivity, timeout, logout, navigate]);

  return <>{children}</>;
};

export default AutoLogout;
