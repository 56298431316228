/* In the NAme of the Father and to the Son and to The Holy Spirit Amen. St Carlos Pray for us, St Thomas Aquinas Pray for US*/
import React from 'react';
import ReactDOM from 'react-dom/client'; // Use 'react-dom/client' for React 18+
import App from './App'; 
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

//import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from './auth/AuthContext'; 
import ErrorBoundary from './ErrorBoundary'; // Import the ErrorBoundary component

// Load CSS file from environment variable
const cssPath = `${process.env.REACT_APP_URL}/styles/css/bootstrap.min.css`; // Ensure REACT_APP_URL is defined in your .env file

const link = document.createElement('link');
link.rel = 'stylesheet';
link.href = cssPath;
//console.log('CSS Path:', cssPath);

link.onload = () => console.log('CSS loaded successfully.');
link.onerror = () => console.error('Error loading CSS file.');
document.head.appendChild(link);

// Create a root to render the App
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the application with AuthProvider
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </AuthProvider>
  </React.StrictMode>
);
