import React from "react";
import MenuBar from "./page/common/MenuPanel"; // Adjust the import path as needed
import FooterPanel from "./page/common/FooterPanel"; // Adjust the import path as needed

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <React.Fragment>
          <div className="container-fluid position-relative p-0">
            <MenuBar />
          </div>
          <div className="container-fluid p-0">
            <div
              className="banner-image_sm"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/2.png)`,
              }}
            >
              <div className="banner-heading text-center wow fadeInDown">
                <h1 className="text-red">Error</h1>
              </div>
            </div>
          </div>
          <p className="error-message">Something went wrong.</p>
          <FooterPanel />
        </React.Fragment>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
