// src/utils/UtilMethods.js
import React from "react"; // Import React

class UtilMethods {
  static formatDateDDMONYYY(dateString) {
    const date = new Date(dateString);
    return date
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .toUpperCase();
  }

  static formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  }
  static formatDateShort(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }
  static formatDateTime(dateString) {
    const date = new Date(dateString);
    return (
      date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      }) +
      " " +
      date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true, // Use 24-hour format; remove this line for 12-hour format
      })
    );
  }

  static canRegister(regDate, startDate) {
    const currentDate = new Date();
    return (
      new Date(regDate) <= currentDate && currentDate <= new Date(startDate)
    );
  }

  static validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    // Check if the email is valid and not equal to the invalid guest email
    return (
      emailRegex.test(email) && email !== process.env.REACT_APP_GUEST_EMAIL
    );
  };
  /**
   * Validates a password.
   *
   * @param {string} password - The password to validate.
   * @returns {string} - Returns "Y" if the password is valid, otherwise an error message.
   */
  static validatePassword = (password) => {
    // Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character
    //const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    // Password must be at least 8 characters long and contain at least one uppercase letter
    const passwordRegex = /^(?=.*[A-Z])[A-Za-z\d@$!_%*:?&-]{8,12}$/;
    // Password must be at least 8 characters long and contain at least one uppercase letter
    const pass = passwordRegex.test(password);
    return pass
      ? "Y"
      : "Password must be between 8 and 12 characters long and contain at least one uppercase letter";
  };
  // Phone validation function
  /**
   * Validates a phone number.
   *
   * @param {string} phone - The phone number to validate.
   * @returns {boolean} - Returns true if the phone number is valid and not equal to the guest phone number, otherwise false.
   */

  static validatePhone = (phone) => {
    return (
      phone && phone.length >= 8 && phone !== process.env.REACT_APP_GUEST_PHONE
    );
  };
  static formatTextWithLineBreaks(text) {
    // Ensure text is a string, default to an empty string if undefined or null
    const safeText = String(text || "");
    return safeText.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line.split(" ").map((word, wordIndex) => (
          <React.Fragment key={wordIndex}>
            {word}
            {wordIndex < line.split(" ").length - 1 && " "}
          </React.Fragment>
        ))}
        <br />
      </React.Fragment>
    ));
  }
  static formatGroupMembers = (members = [], separator = "\n") => {
    if (!Array.isArray(members) || members.length === 0) {
      return "No active members found.";
    }

    const activeMembers = members.filter((member) => !member.deleted);

    if (activeMembers.length === 0) {
      return "No active members found.";
    }

    return activeMembers.map(
      (member) => this.formatGroupMember(member) // Call formatGroupMember with the member object
    );
  };

  static formatGroupMember = (member) => {
    return `\nName: ${member.firstName} ${member.lastName}, Age: ${member.age}, Gender: ${member.gender}`;
  };

  static log(level, message, ...optionalParams) {
    if (process.env.REACT_APP_LOG_MODE === "development") {
      const stack = new Error().stack;
      const callerLine = stack.split("\n")[2];
      const callerInfo = callerLine.match(/at (.+) \((.+):(\d+):(\d+)\)/);
      const callerDetails = callerInfo ? `${callerInfo[1]} at ${callerInfo[2]}:${callerInfo[3]}` : "unknown location";

      switch (level) {
        case "info":
          console.info(`[INFO] ${callerDetails} - ${message}`, ...optionalParams);
          break;
        case "warn":
          console.warn(`[WARN] ${callerDetails} - ${message}`, ...optionalParams);
          break;
        case "error":
          console.error(`[ERROR] ${callerDetails} - ${message}`, ...optionalParams);
          break;
        default:
          console.log(`[LOG] ${callerDetails} - ${message}`, ...optionalParams);
      }
    }
  }
  static error(message, ...optionalParams) {
    if (process.env.REACT_APP_LOG_MODE === "development") {
      console.error(message, ...optionalParams);
    }
  }
}
export default UtilMethods;
